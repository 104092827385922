// eslint-disable-next-line import/prefer-default-export
export const CHANGE_VALUE = 'CHANGE_VALUE';
export const UPDATE_DIMENSIONS = 'UPDATE_DIMENSIONS';

export interface InputsDataState {
  BoardInstallationType: number;
  BoardDimensions: {
    Width: number;
    Height: number;
    Deep: number;
  };
  BoardVentilationHoles: number;
  NumberOfInternalHorizontalFrames: number;
  EffectivePowerLossOfInstalledDevices: number;
  PowerLossOfConductors: number;
  ExtraPowerLoss: number;
  DemandFactor: number;
  ConditioningPower: number;

  // fan parameters
  FanCapacity: number;
  CoefficientOfThermalExchange: number;
  ExternalTemperature: number;
  TemperatureAtMaximumHeight: number;
  MaxAverageInternalTemperature: number;
  // calc control
  CoolingSystem: 0 | 1 | 2;
  TargetOfCalculation: 0 | 1 | 2;
}

export interface UpdateValue {
  type: 'CHANGE_VALUE';
  params: InputsDataState;
}

export interface UpdateDimensions {
  type: 'UPDATE_DIMENSIONS';
  params: InputsDataState['BoardDimensions'];
}
