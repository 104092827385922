import { UpdateErrors, DeleteError, InputsErrorsProps, UPDATE_ERRORS, DELETE_ERROR } from './types.d';
import { LOAD_PROJECT, LoadProject } from '../types.d';

export const initialState: InputsErrorsProps = {};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: KnownAction): InputsErrorsProps => {
  switch (action.type) {
    // is there a better way to do that?
    case UPDATE_ERRORS:
      return {
        ...state,
        ...action.params,
      };
    case DELETE_ERROR: {
      const newState = state;
      delete newState[action.id];
      return newState;
    }
    case LOAD_PROJECT: {
      return {
        ...action.inputErrors,
      };
    }
    default:
      return state;
  }
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = UpdateErrors | DeleteError | LoadProject;
