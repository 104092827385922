import {
  AppState,
  CHANGE_MODE,
  ChangeModeState,
  PUSH_NOTIFICATION,
  PushNotification,
  SET_PARTNER_HUB_DATA,
  SetPartnerHubData,
} from './types.d';
import { LOAD_PROJECT, LoadProject } from '../types.d';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export const initialState = {
  name: 'New project',
  projectLoaded: false,
  projectId: -1,
  isSetupMode: true,
  notifications: [],
  partnerHub: {
    toolUrl: '',
    hookUrlUploadDocuments: '',
    projectId: '',
    otcId: '',
    user: '',
    userName: '',
    projectData: null,
    projectName: '',
    customerName: '',
    otcName: '',
  },
} as AppState;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: KnownAction): AppState => {
  switch (action.type) {
    case CHANGE_MODE:
      return {
        ...state,
        ...action.params,
      };
    case LOAD_PROJECT:
      return {
        ...state,
        ...action.app,
      };
    case PUSH_NOTIFICATION:
      return {
        ...state,
        notifications: [action.notification],
      };
    case SET_PARTNER_HUB_DATA:
      return {
        ...state,
        partnerHub: action.partnerHubData,
      };
    default:
      return state;
  }
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
// type KnownAction = ChangeModeState | LoadProject | PushNotification | SetPartnerHubData;
type KnownAction = ChangeModeState | LoadProject | PushNotification | SetPartnerHubData;
