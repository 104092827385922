const isNumber = (value: number): boolean => Number(value) === value;

const isEmptyString = (value: string | number): boolean => value === '';

export const validateRange = (lowerValue: number, higherValue: number, value: number, name: string): string | void => {
  if ((!value && value !== 0) || value < lowerValue || value > higherValue || !isNumber(value) || isEmptyString(value)) {
    return `${name} should be between ${lowerValue} & ${higherValue}`;
  }
  return undefined;
};

export const validateRangeWithExatLower = (
  lowerValue: number,
  higherValue: number,
  value: number,
  name: string
): string | void => {
  if ((!value && value !== 0) || value <= lowerValue || value > higherValue || !isNumber(value) || isEmptyString(value)) {
    return `${name} should be higher than ${lowerValue} & lower or equal to ${higherValue}`;
  }
  return undefined;
};

export const validateExactRange = (lowerValue: number, higherValue: number, value: number, name: string): string | void => {
  if (!value || value <= lowerValue || value >= higherValue || !isNumber(value) || isEmptyString(value)) {
    return `${name} should be between ${lowerValue} and ${higherValue}. ${name} should not reach points ${lowerValue} and ${higherValue}`;
  }
  return undefined;
};

export const validateExactRangeWithSpecificText = (
  lowerValue: number,
  higherValue: number,
  value: number,
  text: string
): string | void => {
  if (!value || value <= lowerValue || value >= higherValue || !isNumber(value) || isEmptyString(value)) {
    return `${text}`;
  }
  return undefined;
};

export const validateHigherValue = (higherValue: number, value: number, name: string): string | void => {
  if (!value || value > higherValue || !isNumber(value) || isEmptyString(value)) {
    return `${name} 'should be lower than' ${higherValue}`;
  }
  return undefined;
};

export const validateLowerValueWithSpecifiedText = (lowerValue: number, value: number, text: string): string | void => {
  if (!value || value < lowerValue || !isNumber(value) || isEmptyString(value)) {
    return `${text}`;
  }
  return undefined;
};

export const validateHigherValueWithSpecifiedText = (higherValue: number, value: number, text: string): string | void => {
  if (!value || value > higherValue || !isNumber(value) || isEmptyString(value)) {
    return `${text}`;
  }
  return undefined;
};

export const validateLowerValue = (lowerValue: number, value: number, name: string): string | void => {
  if (!value || value < lowerValue || !isNumber(value) || isEmptyString(value)) {
    return `${name} should be greater than ${lowerValue}`;
  }
  return undefined;
};

export const validateExactLowerValue = (lowerValue: number, value: number, name: string): string | void => {
  if (!value || value <= lowerValue || !isNumber(value) || isEmptyString(value)) {
    return `${name} should be greater than ${lowerValue}`;
  }
  return undefined;
};

export const validateIntegerTypeField = (value: number, name: string): string | void => {
  if (!isNumber(value) || !Number.isInteger(value) || isEmptyString(value)) {
    return `${name} should be integer`;
  }
  return undefined;
};

export const validateInputIsNotNan = (value: number, name: string): string | void => {
  if (Number.isNaN(value)) {
    return `${name} 'should be number}`;
  }
  return undefined;
};

export const validateNonEmptyNumberInput = (value: number | string, name: string): string | void => {
  if (value === null || value === undefined || Number.isNaN(value) || (typeof value === 'string' && value.trim() === '')) {
    return `${name} should not be empty`;
  }
  return undefined;
};

export const validateEmptyField = (string: string, name: string): string | void => {
  if (string === '') {
    return `${name} should not be empty`;
  }
  return undefined;
};

export const validateAlreadyExisting = (bool: boolean, name: string | number): string | void => {
  if (!bool) {
    return `${name} already exists`;
  }
  return undefined;
};

export const validateUniqueValue = (values: Array<number | string>, value: number | string): string | void => {
  if (values.some((val) => val === value)) {
    return validateAlreadyExisting(false, value);
  }
  return undefined;
};
