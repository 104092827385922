import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioProps } from './types.d';
import { GridColumn } from '../grid/index';

const RadioGeneric: FunctionComponent<RadioProps> = ({
  col,
  additionalClassName,
  value,
  name,
  id,
  checked,
  label,
  onChange,
  onFocus,
  className,
}): React.ReactElement => {
  const { t } = useTranslation('OTC');
  return (
    <GridColumn col={col || 2} className={`${additionalClassName || ''} label-radio`}>
      <div className="ABB_CommonUX_RadioGroup__root ABB_CommonUX_RadioGroup__light ABB_CommonUX_RadioGroup__smallSize">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          className={className}
          onChange={(event) =>
            onChange ? onChange({ name: event.target.name, value: event.target.value, type: 'radio' }) : null
          }
          onFocus={(event) => (onFocus ? onFocus({ name: event.target.name, value: event.target.value, type: 'radio' }) : null)}
        />
        <span>{t(label ?? '')}</span>
      </div>
    </GridColumn>
  );
};

export default RadioGeneric;
