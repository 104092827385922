/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { ApplicationState, reducers } from '.';

export default function configureStore(initialState?: any): Store<ApplicationState> {
  const middleware = [thunk];
  const rootReducer = combineReducers({
    ...reducers,
  });

  const enhancers: never[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__() as never);
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}
