import React, { FunctionComponent } from 'react';
import { GridRowProps } from './types.d';

const GridRow: FunctionComponent<GridRowProps> = ({ className, children, verticalAlignment, horizontalAlignment, id, style }) => (
  <div
    className={`grid-row ${className} grid-row-vertical-${verticalAlignment} grid-row-horizontal-${horizontalAlignment}`}
    style={style}
    id={id}
  >
    {children}
  </div>
);

export default GridRow;
