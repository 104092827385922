/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectProps } from './types.d';

const GenericSelect: FunctionComponent<SelectProps> = ({
  className,
  name,
  type,
  onChange,
  onFocus,
  disabled,
  data,
  selectList,
  valueList,
  value,
  plainObjectModel,
  valueAttribute,
  borderless,
  displayedAttribute,
}): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const emptySelect = (): ReactElement => (
    <div className={`${className ?? ''} DOCweb_CommonUX_Select__wrapper`}>
      <select
        className={`${borderless ? 'object-properties-input-field' : ''} DOCweb_CommonUX_Select__custom`}
        name={name}
        disabled
      />
    </div>
  );
  const renderObjectList = (): ReactElement => {
    if (!selectList) {
      return emptySelect();
    }
    if (selectList && typeof selectList[0] !== 'object') {
      console.warn('select items with plain object model are not objects', name, selectList);
      return emptySelect();
    }
    let v;
    if (value !== undefined) {
      v = value;
    } else if (data !== undefined) {
      v = data[name || ''];
    }
    return (
      <div className={`${className ?? ''} DOCweb_CommonUX_Select__wrapper`}>
        <select
          className={`${borderless ? 'object-properties-input-field' : ''} DOCweb_CommonUX_Select__custom`}
          name={name}
          onFocus={(e) => {
            if (onFocus) {
              onFocus({
                name,
                value: e.target.value,
              });
            }
          }}
          onChange={(e): void =>
            onChange
              ? onChange({
                  name,
                  value: e.target.value,
                  type: type ?? 'number',
                  data: (selectList as Record<string, unknown>[]).find((item) => {
                    if (typeof item !== 'number') {
                      return item[valueAttribute ?? ''] === e.target.value;
                    }
                    return false;
                  }),
                })
              : undefined
          }
          // type={type === undefined ? 'number' : type}
          value={v as string}
          disabled={disabled}
        >
          <option key="hiddenUnselectableOption" value="none" hidden />
          {(selectList as Record<string, unknown>[]).map((valueNew) => (
            <option
              key={`optionFor${name}${valueNew[valueAttribute ?? ''] as string}`}
              value={valueNew[valueAttribute ?? ''] as string}
            >
              {t(valueNew[displayedAttribute ?? ''] as string)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderArrayList = (): ReactElement => {
    if (!selectList) {
      // console.warn('No arr select list', this.props.name);
      return emptySelect();
    }

    if (selectList && typeof selectList[0] === 'object') {
      console.warn('select items without plain object model are objects', name, selectList);
      return emptySelect();
    }
    return (
      <div className={`${className ?? ''} DOCweb_CommonUX_Select__wrapper`}>
        <select
          className={`${borderless ? 'object-properties-input-field' : ''} DOCweb_CommonUX_Select__custom`}
          name={name}
          onFocus={(e) => {
            if (onFocus) {
              onFocus({
                name,
                value: e.target.value,
              });
            }
          }}
          onChange={(e) =>
            onChange ? onChange({ name, value: e.target.value, type: type === undefined ? 'number' : type }) : undefined
          }
          value={(value as number | string) ?? (data ? data[name ?? ''] : '')}
          disabled={disabled}
        >
          <option key="hiddenUnselectableOption" value="none" hidden />
          {(selectList as string[] | number[]).map((item, key) => {
            let valueItem = item;
            if (valueList !== undefined) {
              valueItem = valueList[key];
            }

            return (
              <option key={`${key}${(selectList as string[] | number[])[key].toString()}`} value={valueItem}>
                {t(item.toString() ?? '')}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  return plainObjectModel === true ? renderObjectList() : renderArrayList();
};

export default GenericSelect;
