import { Configuration, PopupRequest, ProtocolMode } from '@azure/msal-browser';

const environment: Record<string, string> = {
  'https://localhost:5001': '973ea780-b032-48de-9822-707536a7cb9f',
  'https://dev.otcweb.econfigure.abb.com': '973ea780-b032-48de-9822-707536a7cb9f',
  'https://test.otcweb.econfigure.abb.com': '07b82f37-e1d7-4590-94d6-5d997bff69c0',
  'https://otcweb.econfigure.abb.com': 'bf221e34-7a8e-415f-a560-dc08ab7c0da4',
};

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: environment[window.location.origin] || 'bb8294eb-38ff-4d53-8023-484dc3b6e83b',
    authority: 'https://sts.windows.net/372ee9e0-9ce0-4033-a64a-c07073a91ecd/',
    knownAuthorities: ['sts.windows.net'],
    protocolMode: ProtocolMode.AAD,
    redirectUri: window.location.origin,
    // postLogoutRedirectUri: "/"
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};
