export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const DELETE_ERROR = 'DELETE_ERROR';

export interface InputsErrorsProps {
  BoardInstallationType?: string;
  Width?: string;
  Height?: string;
  Deep?: string;
  BoardVentilationHoles?: string;
  NumberOfInternalHorizontalFrames?: string;
  EffectivePowerLossOfInstalledDevices?: string;
  PowerLossOfConductors?: string;
  ExtraPowerLoss?: string;
  DemandFactor?: string;
  FanCapacity?: string;
  CoefficientOfThermalExchange?: string;
  ExternalTemeprature?: string;
  ExternalTemperature?: string;
  MaxAverageInternalTemperature?: string;
  TemperatureAtMaximumHeight?: string;
  ConditioningPower?: string;
  losablePower?: string;
}

export interface UpdateErrors {
  type: 'UPDATE_ERRORS';
  params: {
    [key in keyof InputsErrorsProps]?: string;
  };
}

export interface DeleteError {
  type: 'DELETE_ERROR';
  id: keyof InputsErrorsProps;
}
