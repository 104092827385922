import React from 'react';
import { Grid, GridRow, GridColumn } from 'components/generic/grid';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { SetupProperties, EffectiveCoolingArea } from '../pages/setup-page/component';
import { AdvancedOptionsColumn, Results, ChartArea } from '../pages/results/component';

const MobileSetupPage: React.FunctionComponent = (): React.ReactElement => (
  <Grid>
    <GridRow>
      <GridColumn col={11} className="mobile-setup-panel">
        <SetupProperties />
      </GridColumn>
    </GridRow>
    <GridRow>
      <GridColumn col={12}>
        <EffectiveCoolingArea />
      </GridColumn>
    </GridRow>
  </Grid>
);

const MobileResults: React.FunctionComponent = (): React.ReactElement => (
  <Grid>
    <GridRow>
      <GridColumn col={11} className="mobile-setup-panel">
        <AdvancedOptionsColumn />
      </GridColumn>
    </GridRow>
    <GridRow>
      <GridColumn col={12}>
        <ChartArea />
        <Results />
      </GridColumn>
    </GridRow>
  </Grid>
);

const RenderMobile: React.FunctionComponent = (): React.ReactElement => {
  const app = useSelector((state: ApplicationState) => state.app);
  return <div className="scroll">{app.isSetupMode ? <MobileSetupPage /> : <MobileResults />}</div>;
};

export default RenderMobile;
