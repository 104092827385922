import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import FileReaderInput, { Result } from 'react-file-reader-input';
import { Button } from '@abb/abb-common-ux-react';
import { ApplicationState, useDispatch } from '../../store';
import { changeMode } from './layout/actions';
import { loadProject, saveProject } from '../../project/actions';
import { GridRow, GridColumn } from '../generic/grid';

const NavBar: React.FunctionComponent = (): React.ReactElement => {
  const app = useSelector((state: ApplicationState) => state.app);
  const dispatch = useDispatch();
  const { t } = useTranslation('OTC');
  const projectName =
    app.partnerHub.projectName && app.partnerHub.otcName ? `${app.partnerHub.projectName} - ${app.partnerHub.otcName}` : '';
  return (
    <>
      <GridRow verticalAlignment="center" style={{ flexWrap: 'nowrap' }}>
        <GridColumn col={12} style={{ display: 'flex', alignItems: 'center' }}>
          {!app.isSetupMode ? (
            <Button
              id="button_back"
              text={t('OTC_BUTTON_BACK')}
              type="discreet-blue"
              icon="abb/left-arrow"
              onClick={(): void => changeMode(true)(dispatch)}
            />
          ) : (
            <div className="DOC_OTC_left_panel">{`${projectName}`}</div>
          )}
        </GridColumn>
        <GridColumn col={10} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>
          <FileReaderInput
            as="binary"
            onChange={(event: React.ChangeEvent<HTMLInputElement>, results: Result[]): void => {
              dispatch(loadProject(results, t));
            }}
            accept=".otcweb"
          >
            <Button text={t('OTC_BUTTON_LOADPROJECT')} type="discreet-blue" />
          </FileReaderInput>
          <Button
            text={t('OTC_BUTTON_SAVEPROJECT')}
            type="discreet-blue"
            onClick={(): void => {
              dispatch(saveProject());
            }}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={4}>
          {!app.isSetupMode ? (
            <div className="project-name">{`${app.partnerHub.projectName} - ${app.partnerHub.otcName}`}</div>
          ) : (
            ''
          )}
        </GridColumn>
      </GridRow>
    </>
  );
};
export default NavBar;
