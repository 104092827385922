import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, AppContent } from '@abb/abb-common-ux-react';
import { isMobile } from 'react-device-detect';
import { Grid, GridColumn, GridRow } from '../../generic/grid';
import { changeMode, inputUpdateHandler, onFocusOut } from '../layout/actions';
import { Display, Input, Radio, Select } from '../../generic';
import { ApplicationState, useDispatch } from '../../../store';
import { disposition, sidesCoverage, fanHeight } from '../layout/constants';
import { ISetupEvent, PrintInterface } from './types.d';

export const EffectiveCoolingArea: React.FunctionComponent<PrintInterface> = ({ isInPrint }): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const dispatch = useDispatch();
  const { inputsData } = useSelector((state: ApplicationState) => state);
  const calculationResponseData = useSelector((state: ApplicationState) => state.calculationResponseData);
  const { Width, Height, Deep } = inputsData.BoardDimensions;

  const errorField = (): React.ReactElement | undefined => {
    if (calculationResponseData.error) {
      return (
        <GridRow>
          <GridColumn col={12}>
            <span className="label-warning-message" dangerouslySetInnerHTML={{ __html: t('OTC_WARNING_TOOHIGHAREA') }} />
          </GridColumn>
        </GridRow>
      );
    }
    if (inputsData.BoardDimensions.Width > 1.5) {
      return <span className="label-warning-message">{t('OTC_WARNING_WIDHTHIGHER1500')}</span>;
    }
    return undefined;
  };

  return (
    <Grid className={isMobile ? 'mobile-right-panel' : 'right-panel'}>
      <GridRow>
        <GridColumn col={isMobile ? 10 : 12}>
          <h1>{t('OTC_LABEL_EFFECTIVECOOLINGAREA')}</h1>
          <Display
            label={{ col: 2, text: '' }}
            displayValues={['OTC_DISPLAY_AO[M]', 'OTC_DISPLAY_BFACTOR', 'OTC_DISPLAY_AOxBFACTOR']}
            dangerous
            displayCols={[3, 3, 4]}
          />
          <hr />
          {t('OTC_LABEL_TOPSURFACE')}
          <Display
            label={{
              col: 2,
              text: sidesCoverage[inputsData.BoardInstallationType].Top.coverage,
              className: 'label-effective-cooling-area',
            }}
            displayValues={[
              (Width * Deep).toFixed(2),
              sidesCoverage[inputsData.BoardInstallationType].Top.bFactor,
              (Width * Deep * sidesCoverage[inputsData.BoardInstallationType].Top.bFactor).toFixed(2),
            ]}
            displayCols={[3, 3, 3]}
          />
          <hr />
          {t('OTC_LABEL_FRONTSURFACE')}
          <Display
            label={{
              col: 2,
              text: sidesCoverage[inputsData.BoardInstallationType].Front.coverage,
              className: 'label-effective-cooling-area',
            }}
            displayValues={[
              (Width * Height).toFixed(2),
              sidesCoverage[inputsData.BoardInstallationType].Front.bFactor,
              (Width * Height * sidesCoverage[inputsData.BoardInstallationType].Front.bFactor).toFixed(2),
            ]}
            displayCols={[3, 3, 3]}
          />
          {t('OTC_LABEL_BACKSURFACE')}
          <Display
            label={{
              col: 2,
              text: sidesCoverage[inputsData.BoardInstallationType].Back.coverage,
              className: 'label-effective-cooling-area',
            }}
            displayValues={[
              (Width * Height).toFixed(2),
              sidesCoverage[inputsData.BoardInstallationType].Back.bFactor,
              (Width * Height * sidesCoverage[inputsData.BoardInstallationType].Back.bFactor).toFixed(2),
            ]}
            displayCols={[3, 3, 3]}
          />
          <hr />
          {t('OTC_LABEL_SIDESURFACE')}
          <Display
            label={{
              col: 2,
              text: sidesCoverage[inputsData.BoardInstallationType].SideOne.coverage,
              className: 'label-effective-cooling-area',
            }}
            displayValues={[
              (Deep * Height).toFixed(2),
              sidesCoverage[inputsData.BoardInstallationType].SideOne.bFactor,
              (Deep * Height * sidesCoverage[inputsData.BoardInstallationType].SideOne.bFactor).toFixed(2),
            ]}
            displayCols={[3, 3, 3]}
          />
          <Display
            label={{
              col: 2,
              text: sidesCoverage[inputsData.BoardInstallationType].SideTwo.coverage,
              className: 'label-effective-cooling-area',
            }}
            displayValues={[
              (Deep * Height).toFixed(2),
              sidesCoverage[inputsData.BoardInstallationType].SideTwo.bFactor,
              (Deep * Height * sidesCoverage[inputsData.BoardInstallationType].SideTwo.bFactor).toFixed(2),
            ]}
            displayCols={[3, 3, 3]}
          />
          <hr />
        </GridColumn>
      </GridRow>
      <GridRow>
        <Display
          label={{ col: 3, text: 'OTC_DISPLAU_AEM2', dangerous: true }}
          displayValues={[calculationResponseData.effectiveCoolingAreaOfBoard]}
          displayCols={[3]}
        />
        {isInPrint ? null : (
          <GridColumn col={12} offset={isMobile ? 0 : 8} className={isMobile ? 'mobile-button' : undefined}>
            <Button
              style={isMobile ? { width: '310px' } : undefined}
              text={t('OTC_BUTTON_SEERESULTS')}
              type="primary-blue"
              onClick={(): void => {
                dispatch(changeMode(false));
              }}
            />
          </GridColumn>
        )}
      </GridRow>
      {errorField()}
    </Grid>
  );
};

const VentilationGridArea: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const dispatch = useDispatch();
  const { inputsData, inputErrors } = useSelector((state: ApplicationState) => state);
  return (
    <GridColumn col={isMobile ? 12 : 6} className={isMobile ? 'mobile-ventilation' : undefined}>
      <b>
        {t('OTC_LABEL_VENTILATIONGRIDAREA')} <span dangerouslySetInnerHTML={{ __html: '[cm<sup>2</sup>]' }} />
      </b>
      <Input
        className="margin-top-5"
        col={9}
        type="number"
        name="BoardVentilationHoles"
        onChange={(event): void => {
          dispatch(inputUpdateHandler(event as ISetupEvent));
        }}
        onFocusOut={(event): void => {
          dispatch(onFocusOut(event as ISetupEvent));
        }}
        value={inputsData.BoardVentilationHoles}
        error={inputErrors.BoardVentilationHoles}
      />
    </GridColumn>
  );
};

const FanCapacity: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const dispatch = useDispatch();
  const { inputsData } = useSelector((state: ApplicationState) => state);
  return (
    <>
      <GridRow>
        {inputsData.TargetOfCalculation !== 2 ? (
          <GridColumn col={6}>
            <b>
              {t('OTC_LABEL_FANCAPACITY')} <span dangerouslySetInnerHTML={{ __html: `${t('OTC_DIMENSIONS_FANCAPACITY')}` }} />
            </b>
            <Input
              className="margin-top-5"
              col={9}
              type="number"
              name="FanCapacity"
              onChange={(event): void => {
                dispatch(inputUpdateHandler(event as ISetupEvent));
              }}
              onFocusOut={(event): void => {
                dispatch(onFocusOut(event as ISetupEvent));
              }}
              value={inputsData.FanCapacity}
            />
          </GridColumn>
        ) : null}
        <GridColumn col={6} offset={inputsData.TargetOfCalculation === 2 ? 6 : 0}>
          <b>{t('OTC_SELECT_HEIGHTASL')}</b>
          <Select
            col={9}
            type="number"
            className="margin-top-5"
            name="CoefficientOfThermalExchange"
            value={inputsData.CoefficientOfThermalExchange}
            selectList={fanHeight.display as unknown as Array<string>}
            valueList={fanHeight.value as unknown as Array<number>}
            onChange={(event) => {
              dispatch(inputUpdateHandler(event as unknown as ISetupEvent));
            }}
          />
        </GridColumn>
      </GridRow>
    </>
  );
};

const ConditioningPower: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const dispatch = useDispatch();
  const { inputsData, inputErrors } = useSelector((state: ApplicationState) => state);
  return (
    <GridRow>
      <GridColumn col={6}>
        <b>{`${t('OTC_LABEL_CONDITIONINGPOWER')} [W]`}</b>
        <Input
          className="margin-top-5"
          col={9}
          type="number"
          name="ConditioningPower"
          onChange={(event): void => {
            dispatch(inputUpdateHandler(event as ISetupEvent));
          }}
          onFocusOut={(event): void => {
            dispatch(onFocusOut(event as ISetupEvent));
          }}
          value={inputsData.ConditioningPower}
          error={inputErrors.ConditioningPower}
        />
      </GridColumn>
    </GridRow>
  );
};

export const SetupProperties: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const { inputsData, inputErrors } = useSelector((state: ApplicationState) => state);
  const dispatch = useDispatch();
  return (
    <Grid className={isMobile ? 'mobile-main-grid' : 'main-grid'}>
      <GridRow>
        <GridColumn col={12}>
          <h1>{t('OTC_LABEL_SETUP')}</h1>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={12}>
          <b>{t('OTC_LABEL_DIMENSIONS')}</b>
        </GridColumn>
      </GridRow>
      <GridRow className={isMobile ? undefined : 'margin-top-5'}>
        <GridColumn col={isMobile ? 11 : 6}>
          <Input
            col={isMobile ? 11 : 9}
            name="Height"
            value={inputsData.BoardDimensions.Height * 1000}
            type="number"
            label="OTC_INPUT_HEIGHT"
            error={inputErrors.Height}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
          />
          <Input
            col={isMobile ? 11 : 9}
            name="Deep"
            value={inputsData.BoardDimensions.Deep * 1000}
            label="OTC_INPUT_DEPTH"
            type="number"
            error={inputErrors.Deep}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
          />
        </GridColumn>
        <GridColumn col={isMobile ? 11 : 6}>
          <Input
            col={isMobile ? 11 : 9}
            name="Width"
            value={inputsData.BoardDimensions.Width * 1000}
            label="OTC_INPUT_WIDTH"
            type="number"
            error={inputErrors.Width}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
          />
          <Select
            type="number"
            col={isMobile ? 11 : 9}
            name="NumberOfInternalHorizontalFrames"
            label={{ col: 8, text: 'OTC_SELECT_HORIZONTALFRAMES' }}
            value={inputsData.NumberOfInternalHorizontalFrames}
            selectList={[0, 1, 2, 3, 4, 5]}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
          />
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn col={11}>
          <GridRow>
            <GridColumn col={isMobile ? 11 : 6}>
              <GridRow>
                <b className="label-main">{t('OTC_LABEL_DISPOSITION')}</b>
                <Select
                  col={isMobile ? 12 : 10}
                  name="BoardInstallationType"
                  type="number" // type of values inside list for input.......
                  onChange={(event): void => {
                    dispatch(inputUpdateHandler(event as ISetupEvent));
                  }}
                  selectList={disposition.display as unknown as Array<string>}
                  valueList={disposition.value as unknown as Array<number>}
                  value={inputsData.BoardInstallationType}
                />
              </GridRow>
              {isMobile ? (
                <GridRow>
                  <GridColumn col={isMobile ? 12 : 6} style={{ textAlign: 'center' }}>
                    <img
                      width="80%"
                      className="disposition-picture"
                      src={`assets/images/disposition${inputsData.BoardInstallationType}.jpg`}
                      alt="1"
                    />
                  </GridColumn>
                </GridRow>
              ) : (
                <></>
              )}
              <GridRow>
                <GridColumn col={isMobile ? 11 : 6} className="margin-top-5">
                  <b>{t('OTC_LABEL_COOLING_SYSTEM')}</b>
                  <Radio
                    col={12}
                    additionalClassName=""
                    value="0"
                    name="CoolingSystem"
                    id="CoolingSystemRadio"
                    checked={inputsData.CoolingSystem === 0}
                    label="OTC_RADIO_NATURALVENTILATION"
                    onChange={(event: ISetupEvent): void => {
                      dispatch(inputUpdateHandler(event));
                    }}
                    onFocus={(): void => {}}
                    disabled={false}
                    className="radio-style"
                  />
                  <Radio
                    col={12}
                    additionalClassName=""
                    value="1"
                    name="CoolingSystem"
                    id="CoolingSystemRadio"
                    checked={inputsData.CoolingSystem === 1}
                    label="OTC_RADIO_FORCEDVENTILATION"
                    onChange={(event: ISetupEvent) => {
                      dispatch(inputUpdateHandler(event));
                    }}
                    onFocus={(): void => {}}
                    disabled={false}
                    className="radio-style"
                  />
                  <Radio
                    col={12}
                    additionalClassName=""
                    value="2"
                    name="CoolingSystem"
                    id="CoolingSystemRadio"
                    checked={inputsData.CoolingSystem === 2}
                    label="OTC_RADIO_AIRCONDITIONING"
                    onChange={(event): void => {
                      dispatch(inputUpdateHandler(event as ISetupEvent));
                    }}
                    onFocus={(): void => {}}
                    disabled={false}
                    className="radio-style"
                  />
                </GridColumn>
                <GridColumn col={isMobile ? 11 : 6} className="margin-top-5">
                  <b>{t('OTC_LABEL_TARGETOFCALCULATION')}</b>
                  <Radio
                    col={12}
                    additionalClassName=""
                    value="0"
                    name="TargetOfCalculation"
                    id="radio1"
                    checked={inputsData.TargetOfCalculation === 0}
                    label="OTC_RADIO_TEMPERATUREPROFILE"
                    onChange={(event: ISetupEvent): void => {
                      dispatch(inputUpdateHandler(event));
                    }}
                    onFocus={(): void => {}}
                    disabled={false}
                    className="radio-style"
                  />
                  <Radio
                    col={12}
                    additionalClassName=""
                    value="1"
                    name="TargetOfCalculation"
                    id="radio1"
                    checked={inputsData.TargetOfCalculation === 1}
                    label="OTC_RADIO_LOSABLEPOWER"
                    onChange={(event: ISetupEvent): void => {
                      dispatch(inputUpdateHandler(event));
                    }}
                    onFocus={(): void => {}}
                    disabled={false}
                    className="radio-style"
                  />
                  {inputsData.CoolingSystem === 1 ? (
                    <Radio
                      col={12}
                      additionalClassName=""
                      value="2"
                      name="TargetOfCalculation"
                      id="radio1"
                      checked={inputsData.TargetOfCalculation === 2}
                      label="OTC_LABEL_FANCAPACITY"
                      onChange={(event: ISetupEvent): void => {
                        dispatch(inputUpdateHandler(event));
                      }}
                      onFocus={(): void => {}}
                      disabled={false}
                      className="radio-style"
                    />
                  ) : null}
                  {inputsData.CoolingSystem === 2 ? (
                    <Radio
                      col={12}
                      additionalClassName=""
                      value="2"
                      name="TargetOfCalculation"
                      id="radio1"
                      checked={inputsData.TargetOfCalculation === 2}
                      label="OTC_RADIO_CONDITIONINGPOWER"
                      onChange={(event) => {
                        dispatch(inputUpdateHandler(event));
                      }}
                      onFocus={(): void => {}}
                      disabled={false}
                      className="radio-style"
                    />
                  ) : null}
                </GridColumn>
              </GridRow>
              {/* moved this to the block containing radio buttons */}
            </GridColumn>
            {isMobile ? (
              <></>
            ) : (
              <GridColumn col={6} style={{ textAlign: 'center' }}>
                <img
                  width="100%"
                  style={{ maxWidth: '350px' }}
                  src={`assets/images/disposition${inputsData.BoardInstallationType}.jpg`}
                  alt="1"
                />
              </GridColumn>
            )}
          </GridRow>
          {inputsData.CoolingSystem !== 0 ? <GridRow>{t('OTC_WARNING_METHODNOTBYSTANDARD')}</GridRow> : null}
          {inputsData.CoolingSystem === 0 ? <VentilationGridArea /> : null}
          {inputsData.CoolingSystem === 1 ? <FanCapacity /> : null}
          {inputsData.CoolingSystem === 2 && inputsData.TargetOfCalculation !== 2 ? <ConditioningPower /> : null}
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const Render: React.FunctionComponent = (): React.ReactElement => {
  const app = useSelector((state: ApplicationState) => state.app);
  return (
    <>
      <AppContent className={`DOC_OTC_left_panel ${app.isSetupMode ? 'DOC_OTC_setup_page' : 'DOC_OTC_advanced_options'}`}>
        <AppContent className="DOC_OTC_left_panel_content ">
          <SetupProperties />
        </AppContent>
      </AppContent>
      <AppContent className={`DOC_OTC_right_panel ${app.isSetupMode ? 'DOC_OTC_advanced_cooling_area' : 'DOC_OTC_results_page'}`}>
        <AppContent className="DOC_OTC_right_panel_content ">
          <EffectiveCoolingArea />
        </AppContent>
      </AppContent>
    </>
  );
};
export default Render;
