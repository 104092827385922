export const disposition = {
  display: [
    'OTC_SELECT_DISPOSITIONLABEL0',
    'OTC_SELECT_DISPOSITIONLABEL1',
    'OTC_SELECT_DISPOSITIONLABEL2',
    'OTC_SELECT_DISPOSITIONLABEL3',
    'OTC_SELECT_DISPOSITIONLABEL4',
    'OTC_SELECT_DISPOSITIONLABEL5',
    'OTC_SELECT_DISPOSITIONLABEL6',
  ],
  value: [0, 1, 2, 3, 4, 5, 6],
} as const;

export const demandFactor = {
  display: [
    'OTC_SELECT_DEMANDFACTORLABEL0',
    'OTC_SELECT_DEMANDFACTORLABEL1',
    'OTC_SELECT_DEMANDFACTORLABEL2',
    'OTC_SELECT_DEMANDFACTORLABEL3',
  ],
  value: [0.9, 0.8, 0.7, 0.6],
} as const;

export const fanHeight = {
  display: [
    'OTC_SELECT_FANHEIGHT0',
    'OTC_SELECT_FANHEIGHT1',
    'OTC_SELECT_FANHEIGHT2',
    'OTC_SELECT_FANHEIGHT3',
    'OTC_SELECT_FANHEIGHT4',
  ],
  value: [3.1, 3.2, 3.3, 3.4, 3.5],
} as const;

export const sidesCoverage = [
  {
    Top: { bFactor: 1.4, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Front: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Back: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    SideOne: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    SideTwo: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
  },
  {
    Top: { bFactor: 1.4, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Front: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Back: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideOne: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    SideTwo: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
  },
  {
    Top: { bFactor: 1.4, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Front: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Back: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    SideOne: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideTwo: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
  },
  {
    Top: { bFactor: 1.4, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Front: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Back: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideOne: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideTwo: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
  },
  {
    Top: { bFactor: 1.4, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Front: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Back: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    SideOne: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideTwo: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
  },
  {
    Top: { bFactor: 1.4, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Front: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Back: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideOne: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideTwo: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
  },
  {
    Top: { bFactor: 0.7, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    Front: { bFactor: 0.9, coverage: 'OTC_LABEL_COVERAGE_EXPOSED' },
    Back: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideOne: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
    SideTwo: { bFactor: 0.5, coverage: 'OTC_LABEL_COVERAGE_COVERED' },
  },
] as const;

// these for pdf generation
export const coolingSystemStrings = [
  'OTC_RADIO_NATURALVENTILATION',
  'OTC_PDF_FORCEDVENTILATION',
  'OTC_PDF_AIRCONDITIONING',
] as const;

export const targetOfCalculationStrings = [
  'OTC_RADIO_TEMPERATUREPROFILE',
  'OTC_RADIO_LOSABLEPOWER',
  'OTC_LABEL_FANCAPACITY',
  'OTC_LABEL_CONDITIONINGPOWER',
] as const;
// end
