import { Button, AppContent } from '@abb/abb-common-ux-react';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, EditableSelect, Display } from '../../generic';
import { Grid, GridColumn, GridRow } from '../../generic/grid';
import { inputUpdateHandler, onFocusOut } from '../layout/actions';
import { ApplicationState, useDispatch } from '../../../store';
import { demandFactor } from '../layout/constants';
import { ISetupEvent, PrintInterface } from '../setup-page/types.d';
import { dataURLtoBlob } from '../../../utils';

import { generatePDF } from '../../print-manager/actions';

const svgToImg = (svg: string): string => {
  const buff = Buffer.from(svg);
  return buff.toString('base64');
};

const copyChartToClipboard = (): void => {
  const chartImage = document.getElementsByClassName('OTC_DOC_SVGChart')[0] as HTMLImageElement;
  const canvas = document.createElement('canvas');
  canvas.height = 470;
  canvas.width = 470;
  const ctx = canvas.getContext('2d');
  if (ctx !== null) {
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(chartImage, 0, 0);
  }

  const imgPng = canvas.toDataURL('image/png');
  const blobPng = dataURLtoBlob(imgPng);
  if (blobPng !== null) {
    const blob = new Blob([blobPng], { type: 'image/png' });
    const data = [new window.ClipboardItem({ [blob.type]: blob })];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const myNavigator: any = window.navigator;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    myNavigator.clipboard.write(data).then(
      () => {
        if (canvas !== null) {
          canvas.remove();
        }
        console.log('Copied to clipboard!');
      },
      (err: Error) => {
        if (canvas !== null) {
          canvas.remove();
        }
        console.error(err);
      }
    );
  }
};

const SecondaryTemperatureInput: React.FunctionComponent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { inputsData, inputErrors } = useSelector((state: ApplicationState) => state);
  let label = '';
  if (inputsData.CoolingSystem === 1 || inputsData.CoolingSystem === 0) {
    label = 'OTC_TEMPATMAXHEIGHT';
  } else if (inputsData.CoolingSystem === 2) {
    label = 'OTC_MAXAVGTEMP';
  }

  return (
    <GridRow className="margin-top-5">
      <GridColumn col={11}>
        <Input
          type="number"
          col={11}
          label={label}
          name="MaxAverageInternalTemperature"
          onFocusOut={(event): void => {
            dispatch(onFocusOut(event as ISetupEvent));
          }}
          value={inputsData.MaxAverageInternalTemperature}
          onChange={(event): void => {
            dispatch(inputUpdateHandler(event as ISetupEvent));
          }}
          error={inputErrors.MaxAverageInternalTemperature}
        />
      </GridColumn>
    </GridRow>
  );
};

export const AdvancedOptionsColumn: React.FunctionComponent = (): React.ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation('OTC');
  const { inputsData, inputErrors } = useSelector((state: ApplicationState) => ({
    inputsData: state.inputsData,
    inputErrors: state.inputErrors,
  }));
  return (
    <Grid className={isMobile ? 'mobile-main-grid' : 'main-grid'}>
      <GridRow>
        <GridColumn col={9}>
          <h1>{t('OTC_LABEL_ADDITIONAL_OPTIONS')}</h1>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={12}>
          <b>{`${t('OTC_LABEL_POWERLOSSES')} [W]`}</b>
        </GridColumn>
      </GridRow>
      <GridRow className="margin-top-5">
        <GridColumn col={11}>
          <Input
            col={11}
            name="EffectivePowerLossOfInstalledDevices"
            label="OTC_INPUT_DEVICERATEDPOWERLOSSES"
            type="number"
            value={inputsData.EffectivePowerLossOfInstalledDevices}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
            error={inputErrors.EffectivePowerLossOfInstalledDevices}
          />
          <Input
            col={11}
            name="PowerLossOfConductors"
            label="OTC_INPUT_CONDUCTORPOWERLOSSES"
            type="number"
            value={inputsData.PowerLossOfConductors}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
            error={inputErrors.PowerLossOfConductors}
          />
          {/* cols={isMobile ? [11, 8, 2, 5] : [11, 8, 2, 5]} */}
          <EditableSelect
            col={11}
            name="DemandFactor"
            type="number"
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
            selectList={demandFactor.display as unknown as Array<string>}
            valueList={demandFactor.value as unknown as Array<number>}
            label={{ col: 8, text: 'OTC_INPUT_DEMANDFACTOR' }}
            value={inputsData.DemandFactor}
            error={inputErrors.DemandFactor}
          />
          <Input
            col={11}
            name="ExtraPowerLoss"
            label="OTC_INPUT_EXTRAPOWERLOSSES"
            type="number"
            value={inputsData.ExtraPowerLoss}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
            error={inputErrors.ExtraPowerLoss}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={12}>
          <b>
            {t('OTC_LABEL_TEMPERATUREDATA')} <span dangerouslySetInnerHTML={{ __html: `${t('OTC_DIMENSIONS_CELSIUS')}` }} />
          </b>
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn className={isMobile ? 'mobile-ventilation' : undefined} col={11}>
          <Input
            type="number"
            col={11}
            label="OTC_INPUT_AMBIENTTEMPERATURE"
            name="ExternalTemperature"
            value={inputsData.ExternalTemperature}
            onChange={(event): void => {
              dispatch(inputUpdateHandler(event as ISetupEvent));
            }}
            onFocusOut={(event): void => {
              dispatch(onFocusOut(event as ISetupEvent));
            }}
            error={inputErrors.ExternalTemperature}
          />
        </GridColumn>
      </GridRow>
      {(inputsData.CoolingSystem !== 0 && inputsData.TargetOfCalculation !== 0) ||
      (inputsData.CoolingSystem === 0 && inputsData.TargetOfCalculation === 1) ? (
        <SecondaryTemperatureInput />
      ) : null}
      {inputErrors.losablePower ? (
        <span
          className="label-warning-message"
          style={{ textAlign: 'unset' }}
          dangerouslySetInnerHTML={{ __html: t(inputErrors.losablePower) }}
        />
      ) : null}
    </Grid>
  );
};

// const ChartArea: React.FunctionComponent<ChartInterface> = ({ chart, isInPrint }): React.ReactElement | undefined => {
export const ChartArea: React.FunctionComponent = (): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const { calculationResponseData } = useSelector((state: ApplicationState) => state);
  if (calculationResponseData.error) {
    return <span className="label-warning-message" dangerouslySetInnerHTML={{ __html: t('OTC_WARNING_TOOHIGHAREA') }} />;
  }
  if (calculationResponseData.chart) {
    return (
      <Grid className={isMobile ? 'mobile-chart' : undefined}>
        <GridRow>
          <GridColumn col={11}>
            <img
              id="chartIMG"
              className="OTC_DOC_SVGChart"
              src={`data:image/svg+xml;base64,${svgToImg(calculationResponseData.chart)}`}
              alt="chart"
            />
          </GridColumn>
        </GridRow>
        <GridRow className={isMobile ? 'mobile-chart' : undefined}>
          <GridColumn col={6}>
            <Display
              label={{
                col: 4,
                text: 'OTC_CHART_DELTA1',
                className: 'label-results-page',
                dangerous: true,
              }}
              displayValues={[calculationResponseData.overheatAtTopHeightOfBoard.toFixed(3)]}
              displayCols={[4]}
            />
          </GridColumn>
          <GridColumn col={6}>
            <Display
              label={{
                col: 4,
                text: 'OTC_CHART_DELTA1/2',
                className: 'label-results-page',
                dangerous: true,
              }}
              displayValues={[calculationResponseData.overheatAtMiddleOfBoard.toFixed(3)]}
              displayCols={[4]}
            />
          </GridColumn>
        </GridRow>

        <GridColumn col={isMobile ? 12 : 3} className={isMobile ? 'mobile-button' : undefined}>
          <Button icon="abb/copy" text="Copy" type="discreet-black" onClick={(): void => copyChartToClipboard()} />
        </GridColumn>
      </Grid>
    );
  }
  return <span className="label-warning-message">{t('OTC_LABEL_NOTEMPERATURECURVE')}</span>;
};

// export const Chart: React.FunctionComponent<PrintInterface> = ({ isInPrint }): React.ReactElement => {
//   const { calculationResponseData } = useSelector((state: ApplicationState) => state);
//   const { t } = useTranslation('OTC');
//   return (
//     calculationResponseData.chart
//       ? <ChartArea chart={calculationResponseData.chart} isInPrint={isInPrint} />
//       : <span className="label-warning-message">{t('OTC_LABEL_NOTEMPERATURECURVE')}</span>
//   );
// };

export const Results: React.FunctionComponent<PrintInterface> = ({ isInPrint }): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const { inputsData, calculationResponseData } = useSelector((state: ApplicationState) => state);
  const [print, setPrint] = useState(false);
  const dispatch = useDispatch();
  const TemperatureProfileDisplay = (): React.ReactElement => (
    <>
      <Display
        label={{ col: 8, text: 'OTC_CHART_AMBIENTTEMP' }}
        displayValues={[inputsData.ExternalTemperature]}
        displayCols={[4]}
      />
      <Display
        label={{ col: 8, text: 'OTC_CHART_MAXTEMP' }}
        displayValues={[inputsData.TemperatureAtMaximumHeight.toFixed(2) ?? inputsData.MaxAverageInternalTemperature.toFixed(2)]}
        displayCols={[4]}
      />
    </>
  );

  const LosablePowerCalculationDisplay = (): React.ReactElement => (
    <>
      <Display
        label={{ col: 6, text: 'OTC_DISPLAY_POWERLOSSESATTHEEND' }}
        displayValues={[calculationResponseData.maxPowerLossesAdmittedInTheEnd]}
        displayCols={[6]}
      />
      <Display
        label={{ col: 6, text: 'OTC_DISPLAY_LOSABLEPOWER' }}
        displayValues={[calculationResponseData.losablePower]}
        displayCols={[6]}
      />
    </>
  );

  const conditioningCalculationDisplay = (): React.ReactElement => (
    <>
      <Display
        label={{ col: 6, text: 'OTC_INPUT_POWERSUBSTRACTEDBYCONDITIONING' }}
        displayValues={[calculationResponseData.powerSubtractedByConditioning]}
        displayCols={[6]}
      />
      <Display
        label={{ col: 6, text: 'OTC_DISPLAY_REQUIREDCONDITIONINGPOWER' }}
        displayValues={[calculationResponseData.requiredConditioningPower]}
        displayCols={[6]}
      />
    </>
  );

  const FanCalculationsDisplay = () => (
    <>
      <Display
        label={{ col: 9, text: 'OTC_INPUT_POWERSUBSTRACTEDBYFAN' }}
        displayValues={[calculationResponseData.powerToDissipateByFan]}
        displayCols={[3]}
      />
      <Display
        label={{ col: 9, text: 'OTC_LABEL_FANCAPACITY' }}
        displayValues={[calculationResponseData.fanCapacity]}
        displayCols={[3]}
      />
    </>
  );
  useEffect(() => {
    if (print) {
      document.getElementById('print-button-hidden')?.click();
    }
    setPrint(false);
  }, [print]);
  return (
    <Grid className="right-panel">
      <GridRow>
        <GridColumn col={8}>
          <h1>{t('OTC_LABEL_RESULTS')}</h1>
        </GridColumn>
      </GridRow>
      <GridRow className="margin-top-5">
        <GridColumn col={11}>
          <Display
            label={{ col: 9, text: 'OTC_INPUT_DEVICERATEDPOWERLOSSES' }}
            displayValues={[inputsData.EffectivePowerLossOfInstalledDevices]}
            displayCols={[3]}
          />
          <Display
            label={{ col: 9, text: 'OTC_INPUT_DEMANDFACTOR' }}
            displayValues={[inputsData.DemandFactor]}
            displayCols={[3]}
          />
          <Display
            // according to OTC in e-disign = it's Rated power losses * demand factor
            label={{ col: 9, text: 'OTC_INPUT_DEVICEPOWERLOSSES' }}
            displayValues={[calculationResponseData.powerLoss - inputsData.PowerLossOfConductors - inputsData.ExtraPowerLoss]}
            displayCols={[3]}
          />
          <Display
            label={{ col: 9, text: 'OTC_INPUT_CONDUCTORPOWERLOSSES' }}
            displayValues={[inputsData.PowerLossOfConductors]}
            displayCols={[3]}
          />
          <Display
            label={{ col: 9, text: 'OTC_INPUT_EXTRAPOWERLOSS' }}
            displayValues={[inputsData.ExtraPowerLoss]}
            displayCols={[3]}
          />
          {inputsData.CoolingSystem === 1 ? (
            <>
              <Display
                label={{ col: 9, text: 'OTC_INPUT_POWERSUBSTRACTEDBYFAN' }}
                displayValues={[calculationResponseData.powerToDissipateByFan]}
                displayCols={[3]}
              />
            </>
          ) : null}
          {inputsData.CoolingSystem === 2 ? (
            <>
              <Display
                label={{ col: 9, text: 'OTC_INPUT_POWERSUBSTRACTEDBYCONDITIONING' }}
                displayValues={[calculationResponseData.powerSubtractedByConditioning]}
                displayCols={[3]}
              />
              <Display
                label={{ col: 9, text: 'OTC_INPUT_POWERFROMAMBIENT' }}
                displayValues={[calculationResponseData.powerFromAmbient]}
                displayCols={[3]}
              />
            </>
          ) : null}
          <hr />
          <Display
            label={{ col: 9, text: 'OTC_DISPLAY_TOTALPOWERLOSS' }}
            displayValues={[calculationResponseData.totalPowerLoss.toFixed(2)]}
            displayCols={[3]}
          />
          <hr />
          {inputsData.TargetOfCalculation === 0 ? TemperatureProfileDisplay() : null}
          {inputsData.TargetOfCalculation === 1 ? LosablePowerCalculationDisplay() : null}
          {inputsData.CoolingSystem === 1 && inputsData.TargetOfCalculation === 2 ? FanCalculationsDisplay() : null}
          {inputsData.CoolingSystem === 2 && inputsData.TargetOfCalculation === 2 ? conditioningCalculationDisplay() : null}
        </GridColumn>
        {isInPrint ? null : (
          <GridRow>
            <GridColumn col={isMobile ? 12 : 12} className={isMobile ? 'mobile-button' : undefined}>
              <Button
                icon="abb/print"
                text={t('OTC_BUTTON_PRINT')}
                type="discreet-black"
                disabled={!!calculationResponseData.error}
                onClick={(): void => {
                  void dispatch(generatePDF(t, false));
                  // setPrint(true);
                }}
              />
              <Button
                icon="abb/print"
                text={t('OTC_BUTTON_SEND_TO_PH')}
                type="discreet-black"
                disabled={!!calculationResponseData.error}
                onClick={(): void => {
                  void dispatch(generatePDF(t, true));
                  // setPrint(true);
                }}
              />
            </GridColumn>
          </GridRow>
        )}
      </GridRow>
    </Grid>
  );
};

const Render: React.FunctionComponent = (): React.ReactElement => {
  const app = useSelector((state: ApplicationState) => state.app);
  return (
    <>
      <AppContent className={`DOC_OTC_left_panel ${app.isSetupMode ? 'DOC_OTC_setup_page' : 'DOC_OTC_advanced_options'}`}>
        <AppContent className="DOC_OTC_left_panel_content ">
          <AdvancedOptionsColumn />
        </AppContent>
      </AppContent>
      <AppContent className={`DOC_OTC_right_panel ${app.isSetupMode ? 'DOC_OTC_advanced_cooling_area' : 'DOC_OTC_results_page'}`}>
        <AppContent className="DOC_OTC_right_panel_content ">
          <GridColumn col={6}>
            <Results />
          </GridColumn>
          <GridColumn col={6} verticalAlignment="center">
            <ChartArea />
          </GridColumn>
        </AppContent>
      </AppContent>
    </>
  );
};

export default Render;
