import { Dispatch } from 'redux';
import { UPDATE_ERRORS, DELETE_ERROR, InputsErrorsProps } from '../../../store/input-errors/types.d';
import { CHANGE_MODE } from '../../../store/app/types.d';
import { ApplicationState } from '../../../store';
import { calculate } from '../../../api/calculations';
// import {
//   ProjectDataState, CHANGE_VALUE, UPDATE_DIMENSIONS, UPDATE_CALCULATION_RESULTS, CalculationProps, UPDATE_ERRORS, DELETE_ERROR,
// } from '../../store/projectData/types.d';

import { InputsDataState, CHANGE_VALUE, UPDATE_DIMENSIONS } from '../../../store/inputs-data/types.d';

import { initialState as inputsDataInitialState } from '../../../store/inputs-data/store';
import { UPDATE_CALCULATION_RESULTS } from '../../../store/response-data/types.d';
import * as FieldsValidation from '../../../fields-validation';
import { ISetupEvent } from '../setup-page/types';
import { IRadioEvent } from 'components/generic/radio/types';

// eslint-disable-next-line import/prefer-default-export
export const changeMode =
  (value: boolean) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: CHANGE_MODE,
      params: {
        isSetupMode: value,
      },
    });
  };

const changeValue =
  (params: Partial<InputsDataState>) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: CHANGE_VALUE,
      params,
    });
  };

const updateErrors =
  (name: string, error: string) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: UPDATE_ERRORS,
      params: {
        [name]: error,
      },
    });
  };
const clearErrors =
  (name: string) =>
  (dispatch: Dispatch): void => {
    dispatch({
      type: DELETE_ERROR,
      id: name,
    });
  };

export const runCalculation =
  () =>
  (dispatch: Dispatch, getState: () => ApplicationState): void => {
    const CalculationProperties: InputsDataState = getState().inputsData;
    calculate(CalculationProperties)
      .then(({ data }) => {
        dispatch({
          type: UPDATE_CALCULATION_RESULTS,
          params: { ...data },
        });
        if (data.losablePower < 0) {
          updateErrors('losablePower', 'OTC_ERROR_POWERLOSSESTOOHIGH')(dispatch);
        } else if (getState().inputErrors.losablePower && data.losablePower >= 0) {
          clearErrors('losablePower')(dispatch);
        }
      })
      .catch((err) => console.log(err));
  };

const validate =
  (name: string, value: number, fromFocusOut: boolean) =>
  (dispatch: Dispatch, getState: () => ApplicationState): string | boolean | void => {
    // temperature at maximum height cannot be higher than the ambient temperature,
    let error;
    const { inputsData } = getState();
    switch (name) {
      case 'Height':
      case 'Width':
      case 'Deep':
        error = FieldsValidation.validateLowerValue(0, value, name);
        if (error && !fromFocusOut) {
          updateErrors(name, error)(dispatch);
        }
        break;
      case 'BoardVentilationHoles':
      case 'EffectivePowerLossOfInstalledDevices':
      case 'PowerLossOfConductors':
      case 'ExtraPowerLoss':
        // case 'extraPowerLosses':
        // case 'powerLossOfConductors':
        // case 'effectivePowerLossOfInstalledDevices':
        // case 'boardVentilationHoles':
        error = FieldsValidation.validateNonEmptyNumberInput(value, name);
        if (error && !fromFocusOut) {
          updateErrors(name, error)(dispatch);
        }
        break;
      case 'ExternalTemperature':
        let text = 'OTC_ERROR_AMBIENTTEMPERATURE';
        if (inputsData.CoolingSystem === 2) {
          text = 'Ambient temperature should not be higher than Average Maximum Temperature';
        }
        error = FieldsValidation.validateHigherValueWithSpecifiedText(inputsData.MaxAverageInternalTemperature, value, text);
        if (error && !fromFocusOut) {
          updateErrors(name, error)(dispatch);
        }
        break;
      case 'DemandFactor':
        error = FieldsValidation.validateRange(0, 1, value, name);
        if (error && !fromFocusOut) {
          updateErrors(name, error)(dispatch);
        }
        break;
      // case 'ExternalTemperature':
      //   error = FieldsValidation.validateHigherValueWithSpecifiedText(inputsData.MaximumTemperature, value, 'Ambient Temperature should be lower than Temperature at Maximum Height');
      //   if (error && !fromFocusOut) {
      //     updateErrors(name, error)(dispatch);
      //   }
      //   break;
      case 'MaxAverageInternalTemperature':
        // fix this
        if (inputsData.CoolingSystem === 2) {
          error = FieldsValidation.validateExactRangeWithSpecificText(20, 45, value, 'OTC_ERROR_MAXINTERNALAVGTEMP');
        } else {
          error = FieldsValidation.validateLowerValueWithSpecifiedText(
            inputsData.ExternalTemperature,
            value,
            'OTC_ERROR_TEMPATMAXHEIGHT'
          );
        }
        if (error && !fromFocusOut) {
          updateErrors(name, error)(dispatch);
        }
        break;
      default:
        break;
    }
    return error ?? true;
  };

export const onFocusOut =
  (event: { name: string; type: string; value: string | number }) =>
  (dispatch: Dispatch, getState: () => ApplicationState): void => {
    const value = event.type === 'number' ? parseFloat(event.value as string) : event.value;
    const isValueValid = validate(event.name, value as number, true)(dispatch, getState) === true;
    const { inputsData } = getState();
    if (isValueValid !== true) {
      switch (event.name) {
        case 'Height':
        case 'Deep':
        case 'Width':
          dispatch({
            type: UPDATE_DIMENSIONS,
            params: { [event.name]: 1 }, // for these 3 we have same default value
          });
          clearErrors(event.name)(dispatch);
          break;
        case 'MaxAverageInternalTemperature':
          // if max temp of initial state is lower than current external temp (ambient)
          // then set it to ambient + 1, else set it to initial state's value
          if ((value as number) < inputsData.ExternalTemperature) {
            changeValue({
              [event.name]: inputsData.ExternalTemperature + 1,
            })(dispatch);
            clearErrors(event.name)(dispatch);
          } else {
            changeValue({
              [event.name]: inputsDataInitialState[event.name],
            })(dispatch);
            clearErrors(event.name)(dispatch);
          }
          break;
        default:
          changeValue({
            [event.name]: inputsDataInitialState[event.name as keyof typeof inputsDataInitialState] as unknown as string,
          })(dispatch);
          clearErrors(event.name)(dispatch);
          break;
      }
    } else {
      clearErrors(event.name)(dispatch);
    }
    // if (isValueValid === true) {
    runCalculation()(dispatch, getState);
    // }
  };

export const inputUpdateHandler =
  (event: ISetupEvent | IRadioEvent) =>
  (dispatch: Dispatch, getState: () => ApplicationState): void => {
    const { inputsData } = getState();

    // abb commonUX inputs outputs only strings as values (seems so), api don't accept strings when values should be numbers
    // if parseFloat returns NaN, set value to '',
    const value = event.type === 'number' ? parseFloat(event.value as string) ?? '' : event.value;
    const isValueValid = validate(event.name, value as number, false)(dispatch, getState) === true;
    const { inputErrors } = getState();
    switch (event.name) {
      case 'Height':
      case 'Width':
      case 'Deep':
        dispatch({
          type: UPDATE_DIMENSIONS,
          params: { [event.name]: (value as number) / 1000 }, // (API receives values in m, we display in mm)
        });
        if (isValueValid && inputErrors[event.name as keyof InputsErrorsProps]) {
          clearErrors(event.name)(dispatch);
        }
        break;
      case 'CoolingSystem':
        if (parseFloat(event.value as string) === 0 && inputsData.TargetOfCalculation === 2) {
          changeValue({
            [event.name]: parseFloat(event.value as string) as 0 | 1 | 2, // radio outputs string, we need 0 | 1 | 2
            TargetOfCalculation: 0, // mode 0 doesn't have target of calculation mode 2, so change it back to 0
          })(dispatch);
        } else {
          changeValue({
            [event.name]: parseFloat(event.value as string) as 0 | 1 | 2, // radio outputs string, we need 0 | 1 | 2
          })(dispatch);
        }
        if (
          parseFloat(event.value as string) === 2 &&
          (inputsData.MaxAverageInternalTemperature < 20 || inputsData.MaxAverageInternalTemperature > 45)
        ) {
          changeValue({
            MaxAverageInternalTemperature: inputsDataInitialState.MaxAverageInternalTemperature,
            ExternalTemperature: inputsDataInitialState.ExternalTemperature,
          })(dispatch);
        }
        break;
      case 'TargetOfCalculation':
        changeValue({
          [event.name]: parseFloat(event.value as string) as 0 | 1 | 2, // radio outputs string, we need 0 | 1 | 2
        })(dispatch);
        break;
      default:
        changeValue({
          [event.name]: value, // radio outputs string, we need 0 | 1 | 2
        })(dispatch);
    }
    if (isValueValid === true && typeof isValueValid !== 'string') {
      clearErrors(event.name)(dispatch);
      runCalculation()(dispatch, getState);
    }
  };
