import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Grid, GridRow, GridColumn } from '../generic/grid';
import PrintComponent from './component-print';
import { Ref } from './types.d';

class ComponentToPrint extends React.Component<Ref, object> {
  render() {
    return <PrintComponent />;
  }
}

const PrintManager: React.FunctionComponent = (): React.ReactElement => {
  const componentRef = useRef(null);
  return (
    <Grid className="right-panel display-none">
      <GridRow className="margin-top-5">
        <GridColumn col={8}>
          <ReactToPrint
            bodyClass="printBody"
            trigger={(): React.ReactElement => (
              <button type="button" id="print-button-hidden" style={{ display: 'none' }}>
                Print this out!
              </button>
            )}
            content={(): React.ReactInstance => componentRef.current as unknown as React.ReactInstance}
            removeAfterPrint
            // print={(ee): Promise<any> => exportPDF(ee).catch((error) => console.log(error))}
            // print={(el) => exportPDF(el)}
          />
          <div style={{ display: 'none' }}>
            <ComponentToPrint ref={componentRef} />
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export default PrintManager;
