import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { InputGridProps } from './types.d';
import { GridRow, GridColumn } from '../grid';
import { GridCol } from '../grid/types';

const GenericInput: FunctionComponent<InputGridProps> = ({
  col,
  label,
  labelLeft,
  dimension,
  bold,
  children,
}): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const displayLabel = (): React.ReactElement =>
    bold ? (
      <b>
        <label>{t(label ?? '')}</label>
      </b>
    ) : (
      <label>{t(label ?? '')}</label>
    );

  const standardInput = (colForInputDimensionStringId: GridCol): React.ReactElement => (
    <GridRow>
      {labelLeft ? <GridColumn col={labelLeft.col}>{displayLabel()}</GridColumn> : undefined}
      <GridColumn col={col}>{children}</GridColumn>
      {colForInputDimensionStringId ? (
        <GridColumn
          col={colForInputDimensionStringId}
          className="DOCweb_CommonUX_Label_Centered_for_ABB_Inputs"
          style={{ marginTop: '5px' }}
        >
          {/* not sure we need to translate displayed dimentions, but anyway will do it now, it's not a problem to delete {t()} from this 1 line */}
          <label>{t(dimension ?? '')}</label>
        </GridColumn>
      ) : undefined}
    </GridRow>
  );
  let colForInputDimensionStringId = 12 as GridCol;
  if (dimension) {
    colForInputDimensionStringId = 1;
  }
  return standardInput(colForInputDimensionStringId);
};

export default GenericInput;
