import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectGridProps } from './types.d';
import { GridRow, GridColumn } from '../grid';

const GenericSelect: FunctionComponent<SelectGridProps> = ({
  col,
  divStyle,
  label,
  children,
  editableSelect,
  verticalAlignment,
}): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const displayLabel = (): React.ReactElement => (
    <span className={`DOCweb_CommonUX_Select__label ${label?.className ?? ''}`}>{t(label?.text ?? '')}</span>
  );
  return (
    <GridRow verticalAlignment={verticalAlignment}>
      {label ? <GridColumn col={label.col}>{displayLabel()}</GridColumn> : null}
      <GridColumn col={col || 12} className={`${divStyle ?? ''} ${editableSelect ? 'select-editable' : ''}`}>
        {children}
      </GridColumn>
    </GridRow>
  );
};

export default GenericSelect;
