import React, { FunctionComponent } from 'react';
import { GridColumnProps } from './types.d';

const GridColumn: FunctionComponent<GridColumnProps> = ({
  className,
  children,
  col,
  style,
  id,
  verticalAlignment,
  offset,
  ref,
}) => (
  <div
    className={`grid-column grid-column-${col} ${className} grid-column-vertical-${verticalAlignment} grid-column-offset-${offset}`}
    style={style}
    id={id}
    ref={ref}
  >
    {children}
  </div>
);

export default GridColumn;
