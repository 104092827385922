import React, { FunctionComponent } from 'react';
import Input from './input';
import Grid from './grid';
import { InputProps } from './types';
import { GridCol } from '../grid/types.d';

/**
 * @param row
 * display input in a single row, provide labelLeft? attribute when using it, and don't forget label itself;
 * @param dimension
 * col for grid is always 1 for it when provided
 * @param className
 * className for CommonUX container of input
 * @param inputClassName
 * className for HTML? input inside CommonUX container (need confirmation, but to me it seems so)
 */

interface OTCInputProps extends InputProps {
  col: GridCol;
  labelLeft?: {
    col: GridCol;
  };
  dimension?: string;
}

const OTCInput: FunctionComponent<OTCInputProps> = (props): React.ReactElement => (
  <Grid {...props}>
    <Input {...props} />
  </Grid>
);

export default OTCInput;
