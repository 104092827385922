import axios, { AxiosResponse } from 'axios';
import { msalInstance } from '..';
import { loginRequest } from '../authConfig';

const URL = '/api/';
const { CancelToken } = axios;
const source = CancelToken.source();
const axiosConfig = {
  headers: {
    Pragma: 'no-cache',
    Authorization: '',
  },
  'Content-Type': 'application/json',
  cancelToken: source.token,
  withCredentials: process.env.NODE_ENV !== 'test',
};

const api = axios.create({
  withCredentials: true,
  baseURL: window.location.hostname !== 'localhost' ? process.env.REACT_APP_API_URL : '',
});

const processResponse = <T>(promise: Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> =>
  promise
    .then((response) => {
      if (response.status >= 400) {
        return Promise.reject(new Error(`Invalid response: ${response.status}`));
      }
      return response;
    })
    .then((data) => data)
    .catch((err) => {
      throw err;
      // source.cancel('Operation canceled by the user.');
    });

export default class ApiCall {
  static async get(url: string): Promise<AxiosResponse> {
    axiosConfig.headers = {
      ...axiosConfig.headers,
    };
    return processResponse(api.get(URL + url, axiosConfig));
  }

  static async delete<T, J>(url: string, data?: J): Promise<AxiosResponse<T>> {
    axiosConfig.headers = {
      ...axiosConfig.headers,
    };
    return processResponse(api.delete(URL + url, { ...axiosConfig, data }));
  }

  static async post<T, J>(url: string, data: J): Promise<AxiosResponse<T>> {
    axiosConfig.headers = {
      ...axiosConfig.headers,
    };
    return processResponse(api.post(URL + url, data, axiosConfig));
  }

  static async put<T, J>(url: string, data: J): Promise<AxiosResponse<T>> {
    axiosConfig.headers = {
      ...axiosConfig.headers,
    };
    return processResponse(api.put(URL + url, data, axiosConfig));
  }

  static async patch(url: string, data: unknown): Promise<AxiosResponse> {
    axiosConfig.headers = {
      ...axiosConfig.headers,
    };
    return processResponse(api.patch(URL + url, data, axiosConfig));
  }
}

api.interceptors.request.use(async (config) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account || undefined,
  });

  const bearer = `Bearer ${response.idToken}`;
  // console.log(bearer);
  config.headers.Authorization = bearer;

  return config;
});
