// eslint-disable-next-line import/prefer-default-export
export const UPDATE_CALCULATION_RESULTS = 'UPDATE_CALCULATION_RESULTS';

export interface CalculationResponseProps {
  overheatAtMiddleOfBoard: number;
  overheatAtTopHeightOfBoard: number;
  cFactor: number;
  kFactor: number;
  dFactor: number;
  xFactor: number;
  effectiveCoolingAreaOfBoard: number;
  effectiveCoolingArea: number;
  powerLoss: number;
  maximumPowerLossOfABoard: number;
  powerToDissipateByFan: number;
  chart: string;
  error: string;

  powerFromAmbient: number;
  powerSubtractedByConditioning: number;
  temperatureAtMaximumHeight: number;
  losablePower: number;
  totalPowerLoss: number;
  maxPowerLossesAdmittedInTheEnd: number;
  fanCapacity: number;
  requiredConditioningPower: number;
  correctionFactor: number;
}

export interface UpdateCalculationResults {
  type: 'UPDATE_CALCULATION_RESULTS';
  params: CalculationResponseProps;
}
