export const units: { [key: string]: number } = {
  DemandFactor: 2,
  // BoardVentilationHoles: 2,
  // EffectivePowerLossOfInstalledDevices: 2,
  // PowerLossOfConductors: 2,
  // extraPowerLosses: 2,
  // externalTemperature: 2,
};

/**
 * Use this class to convert some value to basic units
 */
export default class UnitsDataAdapter {
  // to do - remember what this is for
  // static processConvert = (convertedValue: number, name: string): number => {
  //   let stringValue = `${convertedValue}`;
  //   let outputValue;
  //   if (name && units[name] && stringValue.split('.')[1] && stringValue.split('.')[1].length > units[name]) {
  //     const lastNumber = stringValue[stringValue.length - 1];
  //     stringValue = stringValue.slice(0, stringValue.length - 2) + lastNumber;
  //     outputValue = UnitsDataAdapter.cutUnits(name, parseFloat(stringValue));
  //   }
  //   return outputValue || convertedValue;
  // };

  static checkIfNaN(value: string): boolean {
    return Number.isNaN(parseFloat(value));
  }

  /** return -1 if passed value is NaN and output console.log */
  static warning(converter: string, name: string): number {
    console.log(`UnitsDataAdapter: ${converter} passed value is not number or string for ${name}, returning -1`);
    return -1;
  }

  /**
   * Converts value from kilo to base units (value * 10^3)
   * @param {number} value - input value
   */
  static convertKiloToBase(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = value * 10 ** 3;
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertNanoToBase', name)
        : parseFloat(value) * 10 ** 3;
    } else if (typeof value !== 'number' && typeof value !== 'string') {
      console.log(`UnitsDataAdapter: convertNanoToBase: passed value is not number or string for ${name}, returning -1`);
    }
    return convertedValue;
  }

  /**
   * Converts value from Milli to base units (value / 10^3)
   * @param {number | string} value - input value
   */
  static convertMilliToBase(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = value / 10 ** 3;
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertMilliToBase', name)
        : parseFloat(value) / 10 ** 3;
    }
    return convertedValue;
  }

  /**
   * Converts value from Mega to base units (value * 10^6)
   * @param {number | string} value - input value
   */
  static convertMegaToBase(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = value * 10 ** 6;
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertMegaToBase', name)
        : parseFloat(value) * 10 ** 6;
    }
    return convertedValue;
  }

  /**
   * Converts value from Micro to base units (value / 10^6)
   * @param {number | string} value - input value
   */
  static convertMicroToBase(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = value / 10 ** 6;
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertMicroToBase', name)
        : parseFloat(value) / 10 ** 6;
    }
    return convertedValue;
  }

  /**
   * Converts value from Giga to base units (value * 10^9)
   * @param {number | string} value - input value
   */
  static convertGigaToBase(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = value * 10 ** 9;
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertGigaToBase', name)
        : parseFloat(value) * 10 ** 9;
    }
    return convertedValue;
  }

  /**
   * Converts value from Nano to base units (value / 10^9)
   * @param {number | string} value - input value
   */
  static convertNanoToBase(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = value / 10 ** 9;
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertNanoToBase', name)
        : parseFloat(value) / 10 ** 9;
    }
    return convertedValue;
  }

  /**
   * Converts value from percent to base units (value / 100)
   * @param {number | string} value - input value
   */
  static convertPercentToBase(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = value / 100;
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertPercentToBase', name)
        : parseFloat(value) / 100;
    }
    return convertedValue;
  }

  /**
   * Converts value from percent to base units (value * 100)
   * @param {number | string} value - input value
   */
  static convertBaseToPercent(value: string | number, name: string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = parseFloat((value * 100).toFixed(2));
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('convertBaseToPercent', name)
        : parseFloat((parseFloat(value) * 100).toFixed(2));
    }
    return convertedValue;
  }

  /**
   * Cuts units based on its name
   * @param {string | string} name - name of a filed
   */
  static cutUnits(name: string, value: number | string): number {
    let convertedValue = -1;
    if (typeof value === 'number') {
      convertedValue = parseFloat(value.toFixed(units[name]));
    } else if (typeof value === 'string') {
      convertedValue = UnitsDataAdapter.checkIfNaN(value)
        ? UnitsDataAdapter.warning('cutUnits', name)
        : parseFloat(parseFloat(value).toFixed(units[name]));
    }
    return convertedValue;
  }
}
