import React from 'react';
import { AppContent } from '@abb/abb-common-ux-react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

import SetupPage from './setup-page/index';
import CalculationResults from './results/index';

const MainPage: React.FunctionComponent = (): React.ReactElement => {
  const app = useSelector((state: ApplicationState) => state.app);
  return (
    <>
      <AppContent className={`DOC_OTC_left_panel ${app.isSetupMode ? 'DOC_OTC_setup_page' : 'DOC_OTC_advanced_options'}`}>
        <AppContent className="DOC_OTC_left_panel_content advanced-options-height">
          {app.isSetupMode ? <SetupPage /> : <CalculationResults />}
        </AppContent>
      </AppContent>
    </>
  );
};

export default MainPage;
