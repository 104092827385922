import React, { FunctionComponent } from 'react';
import Select from '../select-container/select';
import { EditableSelectProps } from './types.d';
import Input from '../input-container/input';
import Grid from '../select-container/grid';
import { InputProps } from '../input-container/types';

const EditableSelect: FunctionComponent<EditableSelectProps> = (props): React.ReactElement => (
  <Grid {...props} editableSelect>
    <Select
      {...props}
      // label={props.label}
      // onChange={onmouseout || onchange} // what's the idea of this?
    />
    <Input {...(props as unknown as InputProps)} label={undefined} className="editable-input" />
  </Grid>
);

export default EditableSelect;
