import { Dispatch } from 'redux';
import fileDownload from 'js-file-download';
import { Result } from 'react-file-reader-input';
import { TFunction } from 'i18next';
import { ApplicationState } from '../store';
import { LOAD_PROJECT } from '../store/types.d';
import { FileReaderEventTarget } from './types';
import { PUSH_NOTIFICATION, PartnerHubData } from '../store/app/types.d';
import { uuid } from '../utils';

export const saveProject =
  () =>
  (dispatch: Dispatch, getState: () => ApplicationState): void => {
    const calculationProperties: ApplicationState = { ...getState() };
    const projectProperties = { ...getState().app };

    // const project = {
    //   projectProperties,
    //   calculationProperties,
    // };

    const fileContent = JSON.stringify(calculationProperties, null, '\t');
    const fileName = `${projectProperties.name}.otcweb`;
    fileDownload(fileContent, fileName);
  };

export const loadProject =
  (results: Result[], t: TFunction) =>
  (dispatch: Dispatch): void => {
    const uploadError = {
      id: uuid(),
      type: 'banner',
      // Change the Notification type and severity by randomly:
      discreet: true,
      severity: 'alarm',
      timeout: 2000,
      text: t('OTC_PROJECT_UPLOAD_ERROR'),
    };
    const result = results[0];
    if (result[1].name.split('.')[result[1].name.split('.').length - 1] === 'otcweb') {
      const [e] = result;
      const ev = e as ProgressEvent<FileReaderEventTarget>;
      try {
        const projectState = JSON.parse(ev?.target?.result || '') as ApplicationState & {
          app: { partnerHub: PartnerHubData & undefined };
        };
        delete projectState.app.partnerHub;
        dispatch({
          type: LOAD_PROJECT,
          inputsData: projectState.inputsData,
          inputsErrors: projectState.inputErrors,
          calculationResponseData: projectState.calculationResponseData,
          app: projectState.app,
        });
      } catch {
        dispatch({
          type: PUSH_NOTIFICATION,
          notification: uploadError,
        });
      }
    } else {
      dispatch({
        type: PUSH_NOTIFICATION,
        notification: uploadError,
      });
    }
  };
