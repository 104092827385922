import { LoadProject, LOAD_PROJECT } from '../types.d';
import { UpdateCalculationResults, UPDATE_CALCULATION_RESULTS, CalculationResponseProps } from './types.d';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export const initialState = {
  overheatAtMiddleOfBoard: 0,
  overheatAtTopHeightOfBoard: 0,
  cFactor: 0,
  kFactor: 0,
  dFactor: 0,
  xFactor: 0,
  effectiveCoolingAreaOfBoard: 0,
  effectiveCoolingArea: 0,
  powerLoss: 0,
  maximumPowerLossOfABoard: 0,
  powerToDissipateByFan: 0,
  powerFromAmbient: 0,
  powerSubtractedByConditioning: 0,
  chart: '',
  error: '',
  temperatureAtMaximumHeight: 0,
  losablePower: 0,
  totalPowerLoss: 0,
  maxPowerLossesAdmittedInTheEnd: 0,
  fanCapacity: 0,
  requiredConditioningPower: 0,
  correctionFactor: 0,
} as CalculationResponseProps;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: KnownAction): CalculationResponseProps => {
  switch (action.type) {
    // is there a better way to do that?
    case UPDATE_CALCULATION_RESULTS:
      return {
        ...action.params,
      };
    case LOAD_PROJECT:
      return {
        ...state,
        ...action.calculationResponseData,
      };
    default:
      return state;
  }
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = UpdateCalculationResults | LoadProject;
