import { ApplicationState } from '.';
import { AppState } from './app/types.d';
import { InputsErrorsProps } from './input-errors/types.d';
import { InputsDataState } from './inputs-data/types.d';
import { CalculationResponseProps } from './response-data/types.d';

export const LOAD_PROJECT = 'LOAD_PROJECT';

export interface LoadProject extends ApplicationState {
  type: 'LOAD_PROJECT';
  app: AppState;
  inputsData: InputsDataState;
  calculationResponseData: CalculationResponseProps;
  inputsErrors: InputsErrorsProps;
}
