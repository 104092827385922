import '@abb/abb-common-ux-react/styles.css';
import * as React from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import { AbbBar, AppHeader, AppContent, AppContainer, NotificationContainer } from '@abb/abb-common-ux-react';
import './custom.css';

import { isMobile } from 'react-device-detect';
import { runCalculation } from './components/pages/layout/actions';
import MobileOtc from './components/mobile';
import PrintManager from './components/print-manager';
import AutoSave from './components/AutoSave';
import { ApplicationState, useDispatch } from './store';
import { NavBar, MainPage } from './components/pages';
import { SET_PARTNER_HUB_DATA } from './store/app/types.d';
import { getProjectInfo } from './api/partnerHubApi';
import { LOAD_PROJECT } from './store/types.d';
import { AuthenticatedTemplate } from '@azure/msal-react';

export default (): React.ReactElement => {
  const dispatch = useDispatch();

  const appState = useSelector((state: ApplicationState) => state.app);
  const actionHandler = {
    remove: () => {},
  };

  const params = new window.URLSearchParams(window.location.search);
  const otcId = params.get('otcId');
  const toolUrl = params.get('toolUrl');
  const language = params.get('lang');
  // const country = params.get('country');
  const user = params.get('user');

  if (!appState.partnerHub.otcId && otcId !== null && toolUrl !== null && language !== null && user !== null) {
    i18n
      .changeLanguage(language)
      .then(() => {})
      .catch(() => {});
    const partnerHubData = {
      otcId,
      toolUrl,
      user,
    };
    dispatch({
      type: SET_PARTNER_HUB_DATA,
      partnerHubData,
    });
    getProjectInfo(partnerHubData)
      .then(({ data }) => {
        if (data.projectData !== null) {
          const projectState = JSON.parse(data.projectData) as ApplicationState;
          dispatch({
            type: LOAD_PROJECT,
            inputsData: projectState.inputsData,
            inputsErrors: projectState.inputErrors,
            calculationResponseData: projectState.calculationResponseData,
            app: projectState.app,
          });
        } else {
          dispatch({
            type: SET_PARTNER_HUB_DATA,
            partnerHubData: data,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  React.useEffect(() => {
    dispatch(runCalculation());
  }, [dispatch]);
  return (
    <AuthenticatedTemplate>
      <React.Suspense fallback="">
        <AppContainer>
          <AutoSave />
          <NotificationContainer
            actionHandler={actionHandler}
            notifications={appState.notifications}
            style={{ position: 'absolute', top: '0px', right: '0px' }}
          />
          <AppHeader className="DOC_WEB_app_header">
            <AbbBar productName="OTCweb" />
          </AppHeader>
          <AppContent className="ABB_OTC_app_bar">
            <NavBar />
          </AppContent>
          <AppContent className="main-page-container">{isMobile ? <MobileOtc /> : <MainPage />}</AppContent>
        </AppContainer>
        <PrintManager />
      </React.Suspense>
    </AuthenticatedTemplate>
  );
};
