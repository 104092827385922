import { reducer as appReducer } from './app/store';
import { reducer as inputDataReducer } from './inputs-data/store';
import { reducer as calculationResponseReducer } from './response-data/store';
import { reducer as inputErrorsReduces } from './input-errors/store';
import { AppState } from './app/types.d';
import { InputsDataState } from './inputs-data/types';
import { CalculationResponseProps } from './response-data/types';
import { InputsErrorsProps } from './input-errors/types';
import { useStore } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

export interface ApplicationState {
  app: AppState;
  inputsData: InputsDataState;
  calculationResponseData: CalculationResponseProps;
  inputErrors: InputsErrorsProps;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  app: appReducer,
  inputsData: inputDataReducer,
  calculationResponseData: calculationResponseReducer,
  inputErrors: inputErrorsReduces,
};

export default reducers;
// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
type AppDispatch = ThunkDispatch<ApplicationState, unknown, Action<string>>;
export const useDispatch = (): AppDispatch => useStore<ApplicationState>().dispatch;
