/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import { locizePlugin } from 'locize';

export const locizeOptions = {
  projectId: 'dd606fcf-a9bd-4d80-abb0-31e196c591de',
  // apiKey: '', // YOU should not expose your apps API key to production!!!
  referenceLng: 'en',
};

const params = new window.URLSearchParams(window.location.search);

// fallback language for locize in editor mode
let fallbackLanguage: string | undefined = 'en';
const locize = params.get('locize');
if (locize === 'true') {
  fallbackLanguage = undefined;
}

export const initI18n = (): Promise<TFunction | void> =>
  i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    // locize-editor
    // InContext Editor of locize ?locize=true to show it
    // https://github.com/locize/locize-editor
    .use(locizePlugin)
    .init({
      fallbackLng: fallbackLanguage,
      debug: false,
      saveMissing: true,
      defaultNS: 'OTC',
      ns: ['OTC'],
      keySeparator: false, // we do not use keys in form messages.welcome

      // interpolation: {
      //   escapeValue: false, // react already safes from xss
      //   format: (parameters, value: keyof typeof translationParameters, lng) => {
      //     // example of interpolation
      //     let retVal: string[];
      //     if (parameters.param) {
      //       retVal = translationParameters[value](parameters.param)[parameters.key];
      //     } else {
      //       retVal = translationParameters[value] ? translationParameters[value](parameters) : value;
      //     }
      //     return retVal;
      //   },
      // },
      backend: locizeOptions,
      // editor: {
      //   ...locizeOptions,
      //   mode: 'window',
      //   onEditorSaved: async (lng, ns) => {
      //     // reload that namespace in given language
      //     await i18n.reloadResources(lng, ns);
      //     // trigger an event on i18n which triggers a rerender
      //     // based on bindI18n below in react options
      //     i18n.emit('editorSaved');
      //   },
      // },
      react: {
        bindI18n: 'languageChanged editorSaved',
      },
    })
    .catch((err) => console.log('Error in file i18n.ts', err));
// i18n.languages = [];

export default i18n;
