import React, { FunctionComponent } from 'react';
import Select from './select';
import Grid from './grid';
import { DOCSelectProps } from './types';

const OTCSelect: FunctionComponent<DOCSelectProps> = (props): React.ReactElement => (
  <Grid {...props}>
    <Select {...props} />
  </Grid>
);

export default OTCSelect;
