import { LOAD_PROJECT, LoadProject } from '../types.d';
import { InputsDataState, UpdateValue, CHANGE_VALUE, UPDATE_DIMENSIONS, UpdateDimensions } from './types.d';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export const initialState: InputsDataState = {
  BoardInstallationType: 0,
  BoardDimensions: {
    Width: 1,
    Height: 1,
    Deep: 1,
  },
  BoardVentilationHoles: 0,
  NumberOfInternalHorizontalFrames: 0,
  EffectivePowerLossOfInstalledDevices: 100,
  PowerLossOfConductors: 0,
  ExtraPowerLoss: 0,
  DemandFactor: 0.85,
  FanCapacity: 0,
  CoefficientOfThermalExchange: 3.1,
  ExternalTemperature: 20,
  ConditioningPower: 0,
  MaxAverageInternalTemperature: 45,
  TemperatureAtMaximumHeight: 45,
  // calculations control
  TargetOfCalculation: 0,
  CoolingSystem: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const reducer = (state = initialState, action: KnownAction): InputsDataState => {
  switch (action.type) {
    // is there a better way to do that?
    case UPDATE_DIMENSIONS:
      return {
        ...state,
        BoardDimensions: {
          ...state.BoardDimensions,
          ...action.params,
        } as InputsDataState['BoardDimensions'],
      };
    case CHANGE_VALUE:
      return {
        ...state,
        ...action.params,
      };

    case LOAD_PROJECT:
      return {
        ...state,
        ...action.inputsData,
      };
    default:
      return state;
  }
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).

type KnownAction = UpdateValue | UpdateDimensions | LoadProject;
