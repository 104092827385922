import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { AbbBar, AppHeader } from '@abb/abb-common-ux-react';
import { Grid, GridColumn, GridRow } from '../generic/grid';
import { ApplicationState } from '../../store';
import { Display } from '../generic';
import { EffectiveCoolingArea } from '../pages/setup-page/component';
import { disposition } from '../pages/layout/constants';
import { Results, ChartArea } from '../pages/results/component';

const DimensionsOptions: React.FunctionComponent = (): React.ReactElement => {
  const { inputsData } = useSelector((state: ApplicationState) => state);
  const { t } = useTranslation('OTC');
  return (
    <Grid className="right-panel">
      <GridRow>
        <GridColumn col={8}>
          <h1>{t('OTC_PRINTMANAGER_PROPERTIES')}</h1>
        </GridColumn>
      </GridRow>
      <hr />
      <GridRow className="margin-top-5">
        <GridColumn col={8}>
          <Display
            label={{ col: 5, text: 'OTC_LABEL_DISPOSITION' }}
            displayValues={[disposition.display[inputsData.BoardInstallationType]]}
            displayCols={[7]}
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={8}>
          <h1>{t('OTC_LABEL_DIMENSIONS')}</h1>
        </GridColumn>
      </GridRow>
      <hr />
      <GridRow className="margin-top-5">
        <GridColumn col={8}>
          <Display
            label={{ col: 9, text: 'OTC_INPUT_HEIGHT' }}
            displayValues={[inputsData.BoardDimensions.Height * 1000]}
            displayCols={[3]}
          />
          <Display
            label={{ col: 9, text: 'OTC_INPUT_WIDTH' }}
            displayValues={[inputsData.BoardDimensions.Width * 1000]}
            displayCols={[3]}
          />
          <Display
            label={{ col: 9, text: 'OTC_INPUT_DEPTH' }}
            displayValues={[inputsData.BoardDimensions.Deep * 1000]}
            displayCols={[3]}
          />
        </GridColumn>
        <GridColumn col={4}>
          <img width="60%" src={`assets/images/disposition${inputsData.BoardInstallationType}.jpg`} alt="1" />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn col={8}>
          <h1>{t('OTC_LABEL_ADDITIONAL_OPTIONS')}</h1>
        </GridColumn>
      </GridRow>
      <hr />
      <GridRow className="margin-top-5">
        <GridColumn col={8}>
          <Display
            label={{ col: 5, text: 'OTC_INPUT_DEVICERATEDPOWERLOSSES' }}
            displayValues={[inputsData.EffectivePowerLossOfInstalledDevices]}
            displayCols={[7]}
          />
          <Display
            label={{ col: 5, text: 'OTC_INPUT_CONDUCTORPOWERLOSSES' }}
            displayValues={[inputsData.PowerLossOfConductors]}
            displayCols={[7]}
          />
          <Display
            label={{ col: 5, text: 'OTC_INPUT_DEMANDFACTOR' }}
            displayValues={[inputsData.DemandFactor]}
            displayCols={[7]}
          />
          <Display
            label={{ col: 5, text: 'OTC_INPUT_EXTRAPOWERLOSSES' }}
            displayValues={[inputsData.ExtraPowerLoss]}
            displayCols={[7]}
          />
          <Display
            label={{ col: 5, text: 'OTC_INPUT_AMBIENTTEMPERATURE' }}
            displayValues={[inputsData.ExternalTemperature]}
            displayCols={[7]}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

const PrintComponent: React.FunctionComponent = (): React.ReactElement => (
  <Grid>
    <GridRow>
      <GridColumn col={10}>
        <AppHeader className="DOC_WEB_app_header">
          <AbbBar productName="OTCweb" />
        </AppHeader>
        <EffectiveCoolingArea isInPrint />
        <DimensionsOptions />
        <Grid className="margin-top-60">
          <ChartArea />
          <Results isInPrint />
        </Grid>
      </GridColumn>
    </GridRow>
  </Grid>
);

export default PrintComponent;
