import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import { Input } from '@abb/abb-common-ux-react';
import { InputProps } from './types.d';
import UnitsDataAdapter, { units } from '../../../calculations/UnitsDataAdapter';

const GenericInput: FunctionComponent<InputProps> = ({
  className,
  label,
  name,
  type,
  disabled,
  onChange,
  onFocusOut,
  step,
  min,
  max,
  value,
  icon,
  id,
  isRequired,
  showIconInvalid,
  inputClassName,
  row,
  error,
}): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const processInputValue = (): string | number => {
    let val = null;
    if (value !== undefined && !$(`input[name=${name}]`).is(':focus') && units[name]) {
      val = UnitsDataAdapter.cutUnits(name, value);
    } else {
      val = value;
    }
    return val;
  };
  return (
    <>
      <Input
        dataType="number"
        value={type === 'string' ? value.toString() : processInputValue().toString()} // value.toString as commonUX don't want to see number there :/ and don't process value if type is string
        type="normal"
        inputAttributes={{
          name,
          onBlur: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) =>
            onFocusOut ? onFocusOut({ name, value: event.target.value, type }) : undefined,
          onChange: (event: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) =>
            onChange ? onChange({ name, value: event.target.value, type }) : undefined,
          min,
          max,
          step,
          id,
          autoComplete: 'off',
          className: `${error ? 'object-properties-error-message' : ''} ABB_CommonUX_Input__inputElement ${inputClassName ?? ''}`,
        }}
        required={isRequired}
        label={row ? undefined : t(label ?? '')}
        disabled={disabled}
        showValidationBarWhenInvalid
        showValidationIconWhenInvalid={!!showIconInvalid}
        validator={() => error ?? true}
        instantValidation
        icon={icon}
        className={className}
        description={error ? t(error) : undefined}
      />
    </>
  );
};

export default GenericInput;
