import React, { FunctionComponent } from 'react';
import { GridProps } from './types';

const Grid: FunctionComponent<GridProps> = ({ children, className, id }): React.ReactElement => (
  <div className={`grid ${className}`} id={id}>
    {children}
  </div>
);

export default Grid;
