/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/prefer-regexp-exec */
/* eslint-disable no-plusplus */
export const dataURLtoBlob = (dataurl: string): null | Blob => {
  const arr = dataurl.split(',');
  if (arr !== null) {
    const hss = arr[0];
    if (hss !== null) {
      const mime = hss.match(/:(.*?);/)?.[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
  }
  return null;
};

export const uuid = (): string =>
  // credit: http://stackoverflow.com/posts/2117523/revisions
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line one-var
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
