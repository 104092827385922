import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayProps } from './types.d';
import { GridRow, GridColumn } from '../grid';

export const Display: React.FunctionComponent<DisplayProps> = ({
  label,
  displayValues,
  displayCols,
  offset,
  dangerous,
}): React.ReactElement => {
  const { t } = useTranslation('OTC');
  const processDangerous = (string: string): React.ReactElement => <span dangerouslySetInnerHTML={{ __html: string }} />;
  return (
    <GridRow>
      <GridColumn col={label.col} className={label.className} offset={offset}>
        {label.dangerous ? processDangerous(t(label.text)) : t(label.text)}
      </GridColumn>
      {(displayValues || []).map((value, key) => {
        let valueToDisplay;
        if (typeof value === 'string') {
          valueToDisplay = dangerous ? processDangerous(t(value)) : t(value);
        } else {
          valueToDisplay = parseFloat(value.toFixed(2));
        }
        return (
          <GridColumn col={(displayCols ?? [])[key] ?? 1} className="OTCWEB_display-text-aligment" key={`${value} ${key}`}>
            {valueToDisplay}
          </GridColumn>
        );
      })}
    </GridRow>
  );
};
export default Display;
